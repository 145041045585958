// @ts-nocheck
import { useTranslation } from 'next-i18next';
import {
  Map as GoogleMap,
  GoogleApiWrapper,
  GoogleAPI,
  Marker,
  InfoWindow,
} from 'google-maps-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { MapItem, MapItemPoi } from 'data/Map';
import { getMapTheme } from 'utils/mapTheme';
import useDarkMode from 'hooks/useDarkMode';

interface MapProps {
  google: GoogleAPI;
  items: MapItem[];
}

function Map({ items, google }: MapProps) {
  const { t } = useTranslation('common');
  const mapRef = useRef<GoogleMap>(null);
  const [item, setItem] = useState<MapItem | undefined>();
  const [activePoi, setActivePoi] = useState<MapItemPoi | undefined>();
  const [activeMarker, setActiveMarker] = useState<Marker | undefined>();

  const updateMap = useCallback(() => {
    const style = new google.maps.StyledMapType(getMapTheme());
    const map = mapRef.current?.map;
    if (!map) {
      return;
    }

    map.mapTypes.set('map_style', style);
    map.setMapTypeId('map_style');
    map.setOptions({
      mapTypeControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: true,
    });
  }, [google]);

  useDarkMode(updateMap);

  const bounds = useMemo(() => {
    const bounds = new google.maps.LatLngBounds();
    item?.pois.forEach(poi => {
      bounds.extend(
        new google.maps.LatLng(poi.position.latitude, poi.position.longitude),
      );
    });

    return bounds;
  }, [google, item]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  useEffect(() => {
    if (item || items.length === 0) {
      return;
    }
    setItem(items[0]);
  }, [item, items]);

  return (
    <div className="Map">
      <div className="Container">
        <div className="Flag">{t('MAP_TITLE')}</div>
        <div className="Map__content">
          <GoogleMap
            ref={mapRef}
            google={google}
            bounds={bounds}
            initialCenter={{
              lat: 50.7728704,
              lng: 14.1938348,
            }}
          >
            {item?.pois.map((poi, key) => (
              <Marker
                id={key}
                mapCenter={{
                  lat: poi.position.latitude,
                  lng: poi.position.longitude,
                }}
                key={poi.link}
                title={poi.title}
                position={{
                  lat: poi.position.latitude,
                  lng: poi.position.longitude,
                }}
                onClick={(_, marker) => {
                  setActivePoi(poi);
                  setActiveMarker(marker);
                }}
                animation={google.maps.Animation.NONE}
                icon={{
                  url: `/pins/pin-${poi.type}.svg`,
                }}
              />
            ))}
            <InfoWindow
              visible={!!activeMarker}
              marker={activeMarker}
              maxWidth={300}
              pixelOffset={new google.maps.Size(0, 20)}
            >
              {activePoi ? (
                <div className="Map__box">
                  {activePoi.link && (
                    <a
                      href={activePoi.link}
                      target="_blank"
                      className={classNames('Map__boxTitle', {
                        'Map__boxTitle--external':
                          activePoi.external && activePoi.link,
                        'Map__boxTitle--noLink': !activePoi.link,
                      })}
                      rel="noreferrer"
                    >
                      {activePoi.title}
                    </a>
                  )}
                  {!activePoi.link && (
                    <strong
                      className={classNames('Map__boxTitle', {
                        'Map__boxTitle--external':
                          activePoi.external && activePoi.link,
                        'Map__boxTitle--noLink': !activePoi.link,
                      })}
                    >
                      {activePoi.title}
                    </strong>
                  )}
                  <div className="Map__boxDescription">
                    {activePoi.subtitle}
                  </div>
                </div>
              ) : (
                <div />
              )}
            </InfoWindow>
          </GoogleMap>
        </div>
        <div className="Map__types">
          {items.map(i => (
            <div
              key={i.uid}
              onClick={() => {
                setItem(i);
                setActiveMarker(undefined);
                setActivePoi(undefined);
              }}
              className={classNames('Map__type', {
                'Map__type--active': i.uid === item?.uid,
              })}
            >
              {i.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  language: 'cs',
})(Map);
